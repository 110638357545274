<div class="back-btn" (click)="goBack()"></div>

<div *ngIf="step === 0" class="view">
    <ul class="simpleList">
        <li class="simpleList__item"
            (click)="setRecoveryMode('email'); step_next(null)">
          <app-icon width="40" height="40" file="auth" icon="email-restore"></app-icon>
          <span class="simpleList__title">{{ 'auth.restore.password-recovery-with-email'| translate }}</span>
        </li>
        <li class="simpleList__item"
            (click)="setRecoveryMode('mobile_phone'); step_next(null)">
          <app-icon width="40" height="40" file="auth" icon="phone-restore"></app-icon>
          <span class="simpleList__title">{{ 'auth.restore.password-recovery-with-phone'| translate }}</span>
        </li>
    </ul>
</div>

<div *ngIf="step === 1" class="view">
    <app-auth-input-field
        *ngIf="recoveryMode === 'email'"
        class="form__input"
        [form]="form"
        type="email"
        [placeholder]="'auth.email'"
        controlName="email"
        [showValidation]="showValidation">
    </app-auth-input-field>

    <app-auth-input-field
        *ngIf="recoveryMode === 'mobile_phone'"
        class="form__input"
        [form]="form"
        type="phone"
        [placeholder]="'auth.phone'"
        controlName="mobile_phone"
        [showValidation]="showValidation">
    </app-auth-input-field>

    <!-- Captcha -->
    <div class="view__captcha"><div #Captcha action="restore"></div></div>

    <!-- Submit -->
    <button *ngIf="!captchaVisible"
            data-test="submit"
            type="button" class="button signup normal"
            (click)="submit()"> {{ 'auth.restore.action-continue' | translate }}
    </button>

    <!-- Error -->
    <div class="error-content" *ngIf="action_error">{{ action_error | translate }}</div>
</div>


<div *ngIf="step === 2" class="view">
    <p *ngIf="recoveryMode === 'mobile_phone'" class="text send-message">{{ 'auth.signup.send-message' | translate }}</p>
    <p *ngIf="recoveryMode === 'email'" class="text send-message">{{ 'auth.signup.send-message-email' | translate }}</p>
    <ng-otp-input (onInputChange)="onOtpChange($event, recoveryMode)"
        [config]="{allowNumbersOnly: true, length: OTPLength, inputClass: OTPError ? 'is-error' : ''}"></ng-otp-input>
    <p *ngIf="recoveryMode === 'mobile_phone'" class="text not-received-message">{{ 'auth.signup.not-received-message' | translate }}</p>
    <p *ngIf="recoveryMode === 'email'" class="text not-received-message">{{ 'auth.signup.not-received-message-email' | translate }}</p>

    <div class="separator"></div>

    <!-- Error -->
    <div class="error-content" *ngIf="action_error">{{ action_error | translate }}</div>
</div>


<div *ngIf="step === 3" class="view">
    <p class="text-success">
      {{ 'auth.restore.recovery-text.text1'| translate }}
      <br/>
      {{ 'auth.restore.recovery-text.text2'| translate }}
    </p>
    <p class="text-success">
      {{ 'auth.restore.recovery-text.text3'| translate }}
    </p>

    <div class="separator"></div>
    <!-- Submit -->
    <button type="button" class="button signup normal"
            (click)="set_view('signin')"> {{ 'auth.signin.action1' | translate }}
    </button>
</div>
