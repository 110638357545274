import { SocialAuthService } from '@abacritt/angularx-social-login';
import { ChangeDetectorRef, Directive, ElementRef, Input } from '@angular/core';
import { ChangableComponent } from '@app/models/changable.component';
import { ErrorNotificationService } from '@shared/error-notification/error-notification.service';
import { take } from 'rxjs';

declare const google: any;

@Directive({
  selector: 'asl-google-signin-button',
})
export class GoogleSigninButtonDirective extends ChangableComponent {
  @Input()
  type: 'icon' | 'standard' = 'standard';

  @Input()
  size: 'small' | 'medium' | 'large' = 'large';

  @Input()
  text: 'signin' | 'signup_with' = 'signin';

  @Input()
  shape: 'square' | 'circle' | 'pill' | 'rectangular' = 'circle';

  @Input()
  theme: 'outline' | 'filled_blue' | 'filled_black' = 'outline';

  @Input()
  logo_alignment: 'left' | 'center' = 'center';

  @Input()
  width = '155';

  @Input()
  locale = 'En';

  constructor(
    public errorNotificationService: ErrorNotificationService,
    public cdr: ChangeDetectorRef,
    private el: ElementRef,
    socialAuthService: SocialAuthService,
  ) {
    super(cdr);
    socialAuthService.initState.pipe(take(1)).subscribe({
      next: () => {
        google.accounts.id.renderButton(el.nativeElement, {
          type: this.type,
          size: this.size,
          text: this.text,
          width: this.width,
          shape: this.shape,
          theme: this.theme,
          logo_alignment: this.logo_alignment,
          locale: this.locale,
        });
        this.detectChanges();
      },
      error: (error) => {
        this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
      },
    });
  }
}
